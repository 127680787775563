import axios from 'axios'
import { camelizeKeys } from 'humps'

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
})

axiosClient.interceptors.response.use(
  (response) => ({
    ...response,
    data: camelizeKeys(response.data),
  }),
  error => Promise.reject(error),
)

export default axiosClient
