import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import GmtAndScrollWrapper from './components/GmtAndScrollWrapper'
import LazyLoadingPage from './components/LazyLoadingPage/LazyLoadingPage'
import retry from './helpers/lazy-retry'
import Modal from 'Ui/Modal'

const Home = lazy(() => retry(() => import('./components/home/Home')))
const HomeTransfert = lazy(() => retry(() => import('./components/homeTransfert/HomeTransfert')))
const HomeVariant = lazy(() => retry(() => import('./components/home/HomeVariant')))
const HomeCreation = lazy(() => retry(() => import('./components/homeCreation/HomeCreation')))
const SubscriptionPage = lazy(() =>
  retry(() => import('./components/SubscriptionPage/SubscriptionPage')),
)
const EditAddressPage = lazy(() =>
  retry(() => import('./components/EditAddressPage/EditAddressPage')),
)
const AddressPage = lazy(() => retry(() => import('./components/addressPage/AddressPage')))
const LoginPage = lazy(() => retry(() => import('./components/CustomerArea/LoginPage/LoginPage')))
const PasswordResetPage = lazy(() =>
  retry(() => import('./components/CustomerArea/PasswordResetPage/PasswordResetPage')),
)
const NewPasswordPage = lazy(() =>
  retry(() => import('./components/CustomerArea/PasswordResetPage/NewPasswordPage')),
)
const TransfertForm1 = lazy(() => retry(() => import('./components/FormaliteForms/TransfertForm1')))
const TransfertForm2 = lazy(() => retry(() => import('./components/FormaliteForms/TransfertForm2')))
const TransfertForm3 = lazy(() => retry(() => import('./components/FormaliteForms/TransfertForm3')))
const CreationForm1 = lazy(() => retry(() => import('./components/FormaliteForms/CreationForm1')))
const CreationForm2 = lazy(() => retry(() => import('./components/FormaliteForms/CreationForm2')))
const MicroTransfertForm1 = lazy(() => retry(() => import('./components/FormaliteForms/MicroTransfertForm1')))
const MicroTransfertForm2 = lazy(() => retry(() => import('./components/FormaliteForms/MicroTransfertForm2')))
const MicroCreationForm1 = lazy(() => retry(() => import('./components/FormaliteForms/MicroCreationForm1')))
const MicroCreationForm2 = lazy(() => retry(() => import('./components/FormaliteForms/MicroCreationForm2')))
const FormaliteValidatedPage = lazy(() =>
  retry(() => import('./components/FormaliteValidatedPage/FormaliteValidatedPage')),
)
const ContactUsPage = lazy(() => retry(() => import('./components/contactUsPage/ContactUsPage')))
const CustomerArea = lazy(() => retry(() => import('./components/CustomerArea/CustomerArea')))
const LegalNotice = lazy(() => retry(() => import('./components/LegalNotice/LegalNotice')))
const CGUPage = lazy(() => retry(() => import('./components/CGU/CGUPage')))
const CGVPage = lazy(() => retry(() => import('./components/CGU/CGVPage')))
const PolitiqueConfidentialitePage = lazy(() => retry(() => import('./components/CGU/PolitiqueConfidentialitePage')))
const Notification = lazy(() => retry(() => import('./components/Notification/Notification')))
const UnsubscribePage = lazy(() =>
  retry(() => import('./components/unsubscribePage/UnsubscribePage')),
)
const CreationAppointmentPage = lazy(() => retry(() => import('./components/CreationPages/CreationAppointmentPage')))
const OnboardingPage1 = lazy(() => retry(() => import('@pages/Onboarding')))
const OffersPage = lazy(() => retry(() => import('@pages/Offers')))
const DomiciliationPage = lazy(() => retry(() => import('@pages/Domiciliation')))
const OfficesPage = lazy(() => retry(() => import('@pages/Offices')))
const PaymentPage = lazy(() => retry(() => import('@pages/Payment')))
const HomeV2Page = lazy(() => retry(() => import('@pages/HomeV2')))
const OnboardingLoaderPage = lazy(() => retry(() => import('@pages/OnboardingLoader')))
const CreationOnboardingConfirmationPage = lazy(() => retry(() => import('@pages/CreationOnboardingConfirmation')))


class Main extends Component {
  render() {
    return (
      <Router>
        <GmtAndScrollWrapper>
          <Suspense fallback={<LazyLoadingPage />}>
            <div className="main">
              <Switch>
                <Route path="/" exact component={HomeV2Page} />
                <Route path="/home-transfert" exact component={HomeTransfert} />
                <Route path="/1euro" exact component={HomeVariant} />
                <Route path="/inscription" component={SubscriptionPage} />
                <Route path="/inscription-prepaid/adresse" component={EditAddressPage} />
                <Route path="/adresse/:slug" component={AddressPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/reinitialisation" component={PasswordResetPage} />
                <Route path="/reinitialisation-mdp/:token" component={NewPasswordPage} />
                <Route path="/unsubscribe/:token" component={UnsubscribePage} />
                <Route path="/contact" component={ContactUsPage} />
                <Route path="/mon-compte" component={CustomerArea} />
                <Route path="/cgu" component={CGUPage} />
                <Route path="/cgv" component={CGVPage} />
                <Route path="/mentions-legales" component={LegalNotice} />
                <Route path="/politique-confidentialite" component={PolitiqueConfidentialitePage} />
                <Route path="/formalite-payment-validated" component={FormaliteValidatedPage} />
                <Route path="/transfert-de-siege" component={TransfertForm1} />
                <Route path="/transfert-de-siege-dossier" component={TransfertForm2} />
                <Route path="/transfert-de-siege-actionnaires" component={TransfertForm3} />

                <Route path="/creation-entreprise-informations" component={CreationForm1} />
                <Route path="/creation-entreprise-dossier" component={CreationForm2} />
                <Route path="/micro/transfert-de-siege" component={MicroTransfertForm1} />
                <Route path="/micro/transfert-de-siege-dossier" component={MicroTransfertForm2} />
                <Route path="/micro/creation-entreprise-informations" component={MicroCreationForm1} />
                <Route path="/micro/creation-entreprise-dossier" component={MicroCreationForm2} />
                <Route path="/creation-entreprise" exact component={HomeCreation} />
                <Route path="/creation-entreprise-rendez-vous" component={CreationAppointmentPage} />

                <Route path="/creation-entreprise/onboarding" component={OnboardingPage1} />

                <Route path="/creation-entreprise/enregistrement" component={OnboardingLoaderPage} />
                <Route path="/creation-entreprise/offres" component={OffersPage} />
                <Route path="/creation-entreprise/domiciliation" component={DomiciliationPage} />
                <Route path="/creation-entreprise/adresses" component={OfficesPage} />
                <Route path="/creation-entreprise/paiement" component={PaymentPage} />
                <Route path="/creation-entreprise/confirmation-paiement" component={CreationOnboardingConfirmationPage} />

                <Route path="/home-v2" component={HomeV2Page} />
                <Route path="/lp1" component={Home} />

                <Route
                  path="/not-found"
                  status={404}
                  component={({ staticContext }) => {
                    if (staticContext) {
                      staticContext.status = 404
                    }

                    return (
                      <div>
                        <h1>404 : Not Found</h1>
                      </div>
                    )
                  }}
                />
                <Route
                  status={404}
                  component={() => {
                    window.location.replace('/not-found')

                    return null
                  }}
                />
              </Switch>
            </div>
            <Notification />
          </Suspense>
          <Modal />
        </GmtAndScrollWrapper>
      </Router>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(
  MapStateToProps,
  null,
)(Main)
