import React, { Component } from 'react'
import moment from 'moment'

import Button from '../../ui/Button'

import { formatPrice } from '../../../helpers/format-helper'

import './invoice.sass'

export default class InvoiceLine extends Component {
  render() {
    const { date, price, download } = this.props
    const formattedDate = moment.unix(date).format('DD/MM/YYYY')

    return (
      <div className="invoice-line-main">
        <div className="invoice-line-block">
          <img src="/assets/icons/ic-facture.svg" alt={formattedDate} />
          <div className="invoice-left">
            <div className="invoice-title">Facture</div>
            <div className="invoice-date">{formattedDate}</div>
          </div>
        </div>
        <div className="invoice-line-price">{formatPrice(price / 100)} TTC</div>
        <div className="invoice-line-button">
          <Button label="Télécharger" secondaryClass="primary" onClick={download} />
        </div>
      </div>
    )
  }
}
