import React, { useCallback, useMemo, useState } from 'react'
import {
  useStripe, Elements, useElements, PaymentElement, ExpressCheckoutElement,
} from '@stripe/react-stripe-js'
import { Stripe, StripeElements, StripeExpressCheckoutElementConfirmEvent, loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLIC_KEY } from 'Config/constants'
import Button from '@components/Button'
import { formatApiPrice } from 'Helpers/format-helper'
import { notify } from 'Actions/notifications'
import { useDispatch } from 'react-redux'

export type StripePaymentElementProps = {
  clientSecret: string
  amount?: number
  currency?: string
  stripeAccount: string | null
  returnUrl: string
  onPaymentSuccess?: (param: { paymentIntentId: string, stripe: Stripe, elements?: StripeElements }) => void
  onEditSuccess?: (param: { paymentMethodId: string, stripe: Stripe }) => void
  close: () => void
  isEditCb?: boolean
}

const StripePaymentElementForm = ({
  amount, currency, onPaymentSuccess, onEditSuccess, returnUrl, isEditCb
}: Pick<StripePaymentElementProps, 'amount' | 'currency' | 'onPaymentSuccess' | 'onEditSuccess' | 'returnUrl' | 'isEditCb'>) => {
  const stripe = useStripe()
  const elements = useElements()

  const formattedPrice = useMemo(() => (amount && currency) && formatApiPrice(amount, currency), [amount, currency])
  const buttonLabel = amount ? `Payer ${formattedPrice}` : 'Valider'
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const validateEdit = async () => {
    if (!stripe || !elements || !onEditSuccess) return
    
    const res = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl, // no need to really handle for CB, will never be redirected
      },
    })

    const { error, setupIntent } = res

    if (error) {
      setErrorMessage(error.message)
    } else {
      await onEditSuccess({ paymentMethodId: setupIntent.payment_method, stripe })
    }
  }

  const validatePayment = async () => {
    if (!stripe || !elements || !onPaymentSuccess) return

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl,
      },
    })

    if (error) {
      setErrorMessage(error.message)
    } else {
      onPaymentSuccess({ paymentIntentId: paymentIntent.id, stripe, elements })
    }
  }

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()

    if (isEditCb) {
      await validateEdit()
    } else {
      await validatePayment()
    }

    setIsLoading(false)
  }, [elements, setErrorMessage, stripe])

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        isDisabled={!stripe}
        type="submit"
        isLoading={isLoading}
        className="mt-4"
      >
        {buttonLabel}
      </Button>
      {/* Show error message to your customers */}
      {errorMessage && <div className="mt-4 text-pastel-red">{errorMessage}</div>}
    </form>
  )
}

const StripePaymentElement = ({
  clientSecret, amount, currency, stripeAccount, onPaymentSuccess, onEditSuccess, returnUrl, close, isEditCb
}: StripePaymentElementProps) => {
  const stripePromise = useMemo(() => loadStripe(
    STRIPE_PUBLIC_KEY,
    {
      locale: 'fr-FR',
      ...(stripeAccount ? { stripeAccount } : {}),
    },
  ), [stripeAccount])

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <StripePaymentElementForm
        amount={amount}
        currency={currency}
        onPaymentSuccess={(param) => {
          onPaymentSuccess && onPaymentSuccess(param)
          close()
        }}
        onEditSuccess={onEditSuccess}
        returnUrl={returnUrl}
        isEditCb={isEditCb}
      />
    </Elements>
  )
}

export const StripeExpressPaymentElementForm = (
  { onPaymentSuccess, returnUrl }: Pick<StripePaymentElementProps, 'onPaymentSuccess' | 'returnUrl'>
) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const onExpressPaymentConfirm = async (e: StripeExpressCheckoutElementConfirmEvent) => {
    console.log('stripe --------------------------------->')
    console.log(stripe)
    console.log(elements)
    console.log(e)

    if (!stripe || !elements) return

    const { error, paymentIntent } = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl,
      },
    })

    if (error) {
      dispatch(notify('error', error.message))
    } else {
      onPaymentSuccess({ paymentIntentId: paymentIntent.id })
    }

    console.log('paymentIntent --------------------------------->')
    console.log(error)
    console.log(paymentIntent)
  }

  return (
      <ExpressCheckoutElement
        onConfirm={onExpressPaymentConfirm}
        options={{
          buttonType: {
            applePay: 'plain',
            googlePay: 'plain',
          },
          wallets: {
            applePay: 'always',
            googlePay: 'always',
          }
        }}
      />
  )
}

export const StripeExpressPaymentElement = ({
  clientSecret, amount, currency, stripeAccount, onPaymentSuccess, returnUrl, close,
}: StripePaymentElementProps) => {
  const stripePromise = useMemo(() => loadStripe(
    STRIPE_PUBLIC_KEY,
    {
      locale: 'fr-FR',
      ...(stripeAccount ? { stripeAccount } : {}),
    },
  ), [stripeAccount])

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <StripeExpressPaymentElementForm
        onPaymentSuccess={onPaymentSuccess}
        returnUrl={returnUrl}
      />
    </Elements>
  )
}

export default StripePaymentElement
